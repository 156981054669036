$( document ).ready(function() {

  var mainBannerSlider = new Swiper('.gallery-in-article-swiper-container', {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    navigation: {
      nextEl: '.gallery-in-article-swiper-btn-next',
      prevEl: '.gallery-in-article-swiper-btn-prev'
    },
    pagination: {
      el: '.gallery-in-article-swiper-pagination',
      clickable: true
    }
  });

});
