// $( document ).ready(function() {
//   function bindMouse() {
//     $("body").mouseout(function(e) {
//       e = e ? e : window.event;

//       // Get the current viewport width.
//       var vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

//       // If the current mouse X position is within 50px of the right edge
//       // of the viewport, return.
//       if(e.clientX >= (vpWidth - 50))
//         return;

//       // If the current mouse Y position is not within 50px of the top
//       // edge of the viewport, return.
//       if(e.clientY >= 50)
//         return;

//       // Reliable, works on mouse exiting window and
//       // user switching active program
//       var from = e.relatedTarget || e.toElement;

//       if(!from && $.cookie('newsletter-cookie') !== 'close') {
//         $('#newsletter').on('hidden.bs.modal', function (e) {
//           $.cookie('newsletter-cookie', 'close', { expires: 365, path: '/' });
//         })
//         $('#newsletter').modal('show');
//       }
//     });
//   }

//   if($.cookie('newsletter-cookie') !== 'close') {
//     setTimeout(function(){
//       bindMouse();
//     }, 5000);
//   }
// });