let all_places = [];

class PartnersSearch {
	constructor() {
        if ( document.querySelector( '.partners-search' ) ) {
            this.searchCoords = {
                'lon':'',
                'lat':''
            };
            this.filters = [];
            this.initEvents();
        }
    }

	initEvents() {
        let self = this;
        $( document ).ready( function () {
            $.getJSON("/wp-content/themes/dr-eryk/json/places.json", data => {
                $.each( data, function( key, val ) {
                    all_places.push( val );
                });
            });


            $( '#search-place' ).on( 'input', el => {
                if ( el.target.value.length >= 3 ) {
                    self.filterPlaces( el.target.value );
                } else {
                    self.showAutocomplete();
                }
            });

            $( 'input[name="service"]' ).on( 'change', el => {
                self.updateDropdownLabel( $( el.currentTarget ).closest( '.dropdown' ).find( '.dropdown-trigger-label-text' ) );
            });

            $('input[type=checkbox]').on('keypress', function (event) {
                if (event.which === 13) {
                    $(this).prop('checked', !$(this).prop('checked'));
                }
            });

            $( '.js-filter-partner-results' ).on( 'click', e => {
                $( '.partners-search-form .loader' ).show();
                $( '.js-filter-partner-results' ).prop('disabled', true);
                let city = $( '#search-place' ).val();
                if ( city.length ){
                    self.getPlaceCoordinates( city ).then( e => {
                        self.filterPartners();
                    });
                } else {
                    self.searchCoords = {};
                    self.filterPartners();
                }
            })

            $( '.js-partners-search-form-use-location' ).on( 'click', el => {
                el.preventDefault();
                navigator.geolocation.getCurrentPosition( self.getPlaceByPosition, self.showGetLocationError );
            });

            $( document ).on( 'click', '.js-reset-filters', e => {
                e.preventDefault();
                $( 'input[name="service"]:checked' ).trigger('click');
                $( '.js-filter-partner-results' ).trigger('click');
            });

            $( document ).on( 'click', e => {
                let autocompleteGroup = $( '.js-autocomplete-inputgroup' );
                let dropdown = $( '.dropdown' );
                // hide autocomplete input group
                if ( !autocompleteGroup.is(e.target) && autocompleteGroup.has(e.target).length === 0 ) {
                    autocompleteGroup.find( '#matched-place-list' ).html("");
                }
                // close dropdown
                if( !dropdown.is(e.target) && dropdown.has(e.target).length === 0 ) {
                    dropdown.find( '.dropdown-trigger-input' ).prop('checked', false);
                }
            });
        });
    }

    filterPartners() {
        let self = this;
        let topScrollDistance = window.matchMedia( '(min-width: 1200px)' ).matches ? 60 : 0;
        if ( self.filters.length ) {
            $('.partners-card').each( ( index, item ) => {
                let terms = item.dataset.terms.split(',');
                if (  terms.filter( value => self.filters.includes( value )).length ) {
                    $( item ).show();
                } else {
                    $( item ).hide()
                }
            });
        } else {
            $('.partners-card').show()
        }

       
        $('.partners-card').each( ( index, item ) => {
            item.dataset.distance = ( self.searchCoords.lat && self.searchCoords.lon ) ? self.calculateDistance( self.searchCoords.lat, self.searchCoords.lon, item.dataset.lat, item.dataset.lon ) : '0'
        })
    

        let sorted = $('.partners-card:visible').sort( function( a, b ){
            var distanceA = parseFloat( a.dataset.distance );
            var distanceB = parseFloat( b.dataset.distance );
            return (distanceA < distanceB) ? -1 : (distanceA > distanceB) ? 1 : 0;
        });

        if ( ! sorted.length ) {
            $('.partners-results-heading--near').html( 'Nie znaleziono Partnerów spełniających wszystkie kryteria wyszukiwania. <a href="#" class="text-blue js-reset-filters">Wyświetl wszystkich Partnerów w Twoim regionie.</a>' );
        } else {
            let copy = '';
            if ( sorted.length == 1 ) {
                copy = `${ sorted.length } wynik <span class="text-gray">spełniający Twoje parametry</span>`;
            } else if( sorted.length < 5) {
                copy = `${ sorted.length } wyniki <span class="text-gray">spełniające Twoje parametry</span>`;
            } else {
                copy = `${ sorted.length } wyników <span class="text-gray">spełniających Twoje parametry</span>`;
            }
            $('.partners-results-heading--near').html( copy );
        }


        sorted.appendTo('.partners-results-list--near');

        // let nearPartners = sorted.filter( ( index, item ) => {
        //     return parseFloat( item.dataset.distance ) < 50;
        // });
        // let farPartners = sorted.filter( ( index, item ) => {
        //     return ! nearPartners.get().includes( item );
        // });
        // $('.partners-results-heading--near').html( nearPartners.length ? `Najbliżsi partnerzy (${nearPartners.length})` : '');
        // $('.partners-results-heading--far').html( farPartners.length ? 'Pozostali partnerzy' : '');
        // nearPartners.appendTo('.partners-results-list--near');
        // farPartners.appendTo('.partners-results-list--far');

        $( '.partners-search-form .loader' ).hide();
        $( '.js-filter-partner-results' ).prop('disabled', false);

        $('html, body').animate({
            scrollTop: $(".partners-results").offset().top - topScrollDistance
        }, 500);
    }

    calculateDistance( lat1, lon1, lat2, lon2 ) {
        const Radius = 6371;
        let dLat = this.toRadians( lat2 - lat1 );
        let dLon = this.toRadians( lon2 - lon1 ); 
        let distance = 
              Math.sin( dLat/2 ) * Math.sin( dLat/2 ) +
              Math.cos( this.toRadians( lat1 ) ) * Math.cos( this.toRadians( lat2 ) ) * 
              Math.sin( dLon/2 ) * Math.sin( dLon/2 )
              ; 
        distance = 2 * Math.atan2( Math.sqrt( distance ), Math.sqrt(1- distance ) ); 
        distance = Radius * distance; // Distance in km
        return distance;
    }

    toRadians( value ){
        return value * Math.PI / 180;
    }

    filterPlaces( input ) {
        if ( input ) {
            this.showAutocomplete( all_places.filter( ( item ) => item.toLowerCase().startsWith( input.toLowerCase() )) );
        }
    }

    showAutocomplete( places ) {
        let self = this;
        $("#matched-place-list").html("");
        if ( ! places ) {
            return;
        }

        places.forEach( ( place, index ) => {
            $("#matched-place-list").append(`<div class="autocomplete-item" id="item_${ index }">${place}</div>`);
            $("#item_" + index).on( 'click', () => {
                $("#matched-place-list").html("");
                $("#search-place").val( place );
                $('.js-autocomplete-inputgroup .error').remove();
            });
        });
    }

    getPlaceByPosition( position ) {
        $('.js-partners-search-form-use-location .error').remove();
        let reverseGeocoding = `https://nominatim.openstreetmap.org/reverse?lat=${ position.coords.latitude }&lon=${ position.coords.longitude }&format=json&accept-language=pl`;
        $.getJSON( reverseGeocoding ).done( function( location ) {
            let place = location.address.city ? location.address.city : ( location.address.village ? location.address.village : location.address.hamlet );
            $('#search-place').val( place );
            $('#search-place').blur();
        });
    }

    getPlaceCoordinates( place ) {
        let self = this;
        let search = place.replace('gm.', 'gmina');
        let geocoding = `https://nominatim.openstreetmap.org/search?q=${ search }&format=json&accept-language=pl`;
        $('.js-autocomplete-inputgroup .error').remove();
        return $.getJSON( geocoding ).done( function( location ) {
            if( location.length ) {
                self.searchCoords.lat = location[0].lat;
                self.searchCoords.lon = location[0].lon;
            } else {
                self.searchCoords = {};
                if( ! $('.js-autocomplete-inputgroup .error').length ) {
                    $('.js-autocomplete-inputgroup').append( '<div class="error">Nie znaleziono miejscowości.</div>');
                }
            }
        });
    }

    showGetLocationError( err ) {
        if( 1 == err.code && ! $('.js-partners-search-form-use-location .error').length ) {
            $('.js-partners-search-form-use-location').append('<div class="error">Aby skorzytać z tej funkcji musisz zezwolić na dostęp do lokalizacji.</div>')
        }
    }

    updateDropdownLabel( dropdownLabel ) {
        let services = $( 'input[name="service"]:checked' )
        let servicesNumber = services.length;
        let text = 'Wszystkie';
        this.filters = services.map( ( i, e ) => {return e.dataset.termid} ).get();
        if ( servicesNumber == 1 ){
            text = '1 usługa';
        } else if ( servicesNumber > 1 && servicesNumber < 5 ) {
            text = servicesNumber + ' usługi';
        } else if ( servicesNumber >= 5 ) {
            text = servicesNumber + ' usług';
        }
        dropdownLabel.text( text )
    }
}

let partnersSearch = new PartnersSearch();