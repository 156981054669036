$( document ).ready(function() {

  $('.idea-form-form').validate({
    validClass: "",
    errorElement: "span",
    focusInvalid: false,
    focusCleanup: false,
    errorPlacement: function(error, element) {
      $(error).addClass('input-feedback');
      error.insertAfter(element.parent());
    },
    submitHandler: function(form) {
      var $form = $(form);
      $('.form-feedback', $form).remove();

      var data = {
        'action': 'idea_form_submit',
        'idea': $('[name="idea"]',$form).val(),
        'id': $('[name="id"]',$form).val()
      };

      $.ajax({
        type: "POST",
        url: ajaxurl,
        data: data
      }).done(function(res) {
        var response = JSON.parse(res);
        if(response.status == 'ok') {
          $ok = $('<span class="has-success form-feedback">' + response.message + '</span>');
          $form.append($ok);
        } else {
          $error = $('<span class="has-error form-feedback">' + response.message + '</span>');
          $form.append($error);
        }
      })
      .fail(function() {
        $error = $('<span class="has-error form-feedback">Błąd zapisu, proszę spróbować jeszcze raz</span>');
        $form.append($error);
      });

      return false;
    }
  });

});
