'use strict';

$(document).ready(function () {
  function showLoader() {
    $('.load-more').addClass('loading');
  };

  function hideLoader() {
    $('.load-more').removeClass('loading');
  }

  function loadContent(destUrl) {
    $.ajax({
      url: destUrl,
      beforeSend: showLoader(),
      success: function(results) {
        var obj = $(results);
        var content = obj.find('[data-load-content] > div');
        $('[data-load-content]').append(content);

        var next = obj.find('[data-toggle="load-more"]');
        if( next.length !== 0 ){
          $('[data-toggle="load-more"]').attr( 'href', next.attr('href') );
        } else {
          $('.load-more').remove();
        }

        hideLoader();
        new FadeScrolling();
      },
      error: function(results) {
        hideLoader();
      }
    });
  }


  $('[data-toggle="load-more"]').click(function () {
    var href = $(this).attr('href');
    loadContent(href);
    return false;
  });
});
