
$.validator.addMethod( "email", function( value, element ) {
  return this.optional( element ) || /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test( value );
});

$.validator.setDefaults({
  errorElement: "span",
  errorClass: "has-error",
  validClass: "has-success",
  errorPlacement: function(error, element) {
    if($(element).closest('.simple-checkbox').length > 0) {
      error.insertAfter($(element).closest('.simple-checkbox'));
    } else {
      $(element).after(error);
    }
  },
});
