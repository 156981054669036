$( document ).ready(function() {

  var validateNewsletter = {
    validClass: "",
    errorElement: "span",
    focusInvalid: false,
    focusCleanup: false,
    errorPlacement: function(error, element) {
      $(error).addClass('input-feedback');
      error.insertAfter(element.parent());
    },
    submitHandler: function(form) {
      var $form = $(form);
      var url = $form.attr('action');
      $('.form-feedback', $form).remove();
      $form.find('.btn').prop('disabled', true);

      $.ajax({
        type: "POST",
        url: url,
        data: $form.serialize(),
        dataType: 'text'
      }).done(function(res) {
        var response = JSON.parse(res);
        if(response.status == 'ok') {
          $ok = $('<span class="has-success form-feedback">' + response.message + '</span>');
          $form.append($ok);
        } else {
          $error = $('<span class="has-error form-feedback">' + response.message + '</span>');
          $form.append($error);
        }
      })
      .fail(function() {
        $error = $('<span class="has-error form-feedback">Błąd zapisu, proszę spróbować jeszcze raz</span>');
        $form.append($error);
      })
      .always(function() {
        $form.find('.btn').prop('disabled', false);
      });

      return false;
    }
  }

  //$('.news-newsletter-contact-form, .news-full-contact-form, .modal-contact-newsletter-form')
  //$('.news-newsletter-contact-form, .news-full-contact-form, .modal-contact-newsletter-form').validate(validateNewsletter);

  $('.news-newsletter-contact-form, .news-full-contact-form, .modal-contact-newsletter-form').each(function(){
    $(this).append('<input type="hidden" name="secret" value="uvj3ca"  />');
    $(this).validate(validateNewsletter);
  });

});
