function saveCookieSettings () {
	let cookieInputs = $('.cookies-modal').find( '.toogle-input' );

	cookieInputs.each( function( index, e ) {
		if ( $(e).is(':checked') ) {
			$.cookie( 'cookies_'+$(e).attr('data-cookie'), '1', {
				expires: 1000,
				path: '/'
			});
		}
	});
	$( '.cookies-modal' ).hide();
	$( 'body' ).removeClass( 'body--prevent' );
	window.location.reload();
}

$( document ).ready( function () {
	$( '[data-toggle="cookies-bar-accept"]' ).click( function ( e ) {
		e.preventDefault();
		$( '.cookies-bar' ).remove();
		saveCookieSettings();
		$.cookie( 'cookiesaccept', 'accept', {
			expires: 1000,
			path: '/'
		});
	});

	$( '[data-toggle="cookies-bar-customize"]' ).click( function ( e ) {
		e.preventDefault();
		$( '.cookies-bar' ).hide();
		$( '.cookies-modal' ).show();
	});

	$( '[data-toggle="cookies-save-settings"]' ).click( function ( e ) {
		e.preventDefault();
		saveCookieSettings();
		$( '[data-toggle="cookies-bar-accept"]' ).trigger( 'click' );
	});


	$( '[data-toggle="cookies-accept-minimal"]' ).click( function ( e ) {
		e.preventDefault();
		let cookieInputs = $( e.currentTarget ).closest('.cookies-modal').find( '.toogle-input' );

		cookieInputs.each( function( index, e ) {
			if ( $(e).is(':not(:disabled)') ) {
				$(e).prop('checked', false);
			}
		});

		saveCookieSettings();
		$( '[data-toggle="cookies-bar-accept"]' ).trigger( 'click' );
	});

	if ( $.cookie('cookiesaccept') !== 'accept' ) {
		$( '.cookies-bar' ).addClass( 'show' );
		$('body').addClass( 'body--prevent' );
	}
});