$( document ).ready(function() {

  $('[data-toggle="video-modal"]').click(function(e){
    var video = $(this).data('video');
    var youtube = $(this).data('youtube');
    if(video) {
      var videoHtml = $('<video controls autoplay class="js-player video-player"><source src="' + video + '" type="video/mp4"></video>');
    } else {
      var videoHtml = $('<div class="js-player video-player" data-plyr-provider="youtube" data-plyr-embed-id="' + youtube + '"></div>');
    }

    var title = $(this).find('.video-lead-title').html();

    $('#video-modal .modal-header').append('<h2 class="modal-header-title">' + title + '</h2>');
    $('#video-modal .modal-video').html(videoHtml);

    new Plyr('#video-modal .js-player', {
      autoplay: true
    });

    $('#video-modal').modal('show');

    e.preventDefault();
  });

  $('#video-modal').on('hidden.bs.modal', function (e) {
    $('#video-modal .modal-header-title').remove();
    $('#video-modal .modal-video').html('');
  })
});
