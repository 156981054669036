$( document ).ready(function() {

  /*******************************************************
 ***************************************** Navbar
  *******************************************************/
  if($('.page-subnav').length > 0) {
    $('body').addClass('page-navbar-subnav');
  }
  if($('.page-subnav-hide-xs').length > 0) {
    $('body').addClass('page-navbar-subnav-hide-xs');
  }


 var didScroll;
 var lastScrollTop = 0;
 var delta = 5;

 $(window).scroll(function(event){
   didScroll = true;
 });

 hasScrolled();

 setInterval(function() {
   if (didScroll) {
     hasScrolled();
     didScroll = false;
   }
 }, 250);

 function hasScrolled() {
   var scrollTop = $(this).scrollTop();

   // Make sure they scroll more than delta
   if(Math.abs(lastScrollTop - scrollTop) <= delta)
     return;

   // Reset when scroll = 0
   if(scrollTop <= 0) {
     $('body').removeClass('page-navbar-shadow').removeClass('page-navbar-hide');
     return;
   }

   // If they scrolled down and are past the navbar, add class .nav-up.
   // This is necessary so you never see what is "behind" the navbar.
  if (scrollTop > lastScrollTop){
     // Scroll Down
     $('body').removeClass('page-navbar-shadow').addClass('page-navbar-hide');
  } else {

     // Scroll Up
     if(scrollTop + $(window).height() < $(document).height()) {
       $('body').removeClass('page-navbar-hide').addClass('page-navbar-shadow');
     }
  }

  if(scrollTop > 0) {
    lastScrollTop = scrollTop;
  }
 }



  /*******************************************************
  ***************************************** page hader
  *******************************************************/
  $('[data-toggle="navbar"]').click(function(e){
    if($('body').hasClass('open-navbar')) {
      $(this).addClass('collapsed');
      $('.page-header-navbar').removeClass('in');
      $('body').removeClass('open-navbar');
      $('.page-header-toggle-text').html('Menu');
    } else {
      $(this).removeClass('collapsed');
      $('.page-header-navbar').addClass('in');
      $('body').addClass('open-navbar');
      $('.page-header-toggle-text').html('Zamknij');
    }
    e.preventDefault();
  });

  $('[data-toggle="subnav"]').click(function(e){
    $(this).toggleClass('collapsed');
    $('.page-subnav-nav').toggleClass('in');
    e.preventDefault();
  });

});
