'use strict';

function scrollToElement(target, time) {
  var offset;

  if ($(target).length > 0 && !$(this).hasClass('disabled')) {
    var offset = $(target).offset();
    var headerHeight = $('.page-header').outerHeight();
    $('html, body').animate({
      scrollTop: offset.top - headerHeight
    },
    time);
  }
}

$(document).ready(function () {
  $('[data-toggle="scroll-to"]').click(function () {
    var target = $(this).attr('href');

    if ($(target).length > 0 && !$(this).hasClass('disabled')) {
      scrollToElement(target, 600);
    }
    return false;
  });

  $('[data-toggle="scroll-to-top"]').click(function () {

    if (!$(this).hasClass('disabled')) {
      $('html, body').animate({
        scrollTop: 0
      },
      600);
    }
    return false;
  });



  if (window.location.hash) {
    setTimeout(function() {
      var id = window.location.hash.replace('-link', '');
      scrollToElement(id, 600);
    }, 100);
  }
});
