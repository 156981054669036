function startBaseHelper(context){

  context = context || 'body';

  /*******************************************************
   ***************************************** target blank
   *******************************************************/
  $('a.external, .btn-login a', context).attr('target', '_blank');


  $('a.disabled', context).click(function(e){
    return false;
  })


  /*******************************************************
   ***************************************** js player
   *******************************************************/
  const player = new Plyr('.js-player');


  /*******************************************************
   ***************************************** select
   *******************************************************/
  $('select', context).selectpicker({
    'title': 'Wybierz z listy',
    'size': 5
  });


  /*******************************************************
   ***************************************** modal form
   *******************************************************/
  $('[href="#modal-pobierz-demo"]', context).click(function(e){
    $('#modal-pobierz-demo').modal('show');
    e.preventDefault();
  });
  $('[href="#modal-produkty-kontakt"]', context).click(function(e){
    $('#modal-produkty-kontakt').modal('show');
    e.preventDefault();
  });


  $('[href="#modal-skontaktuj-sie"]', context).click(function(e){
    $('#modal-skontaktuj-sie').modal('show');
    e.preventDefault();
  });

  $('[href="#kontakt"]', context).click(function(e){
    $('#kontakt').modal('show');
    e.preventDefault();
  });

  $('[href="#newsletter"]', context).click(function(e){
    $('#newsletter').modal('show');
    e.preventDefault();
  });

  $('[href*="#modal-form"]', context).click(function(e){
    var href = $(this).attr('href');
    $(href).modal('show');
    e.preventDefault();
  });




  /*******************************************************
   ***************************************** move modal to end of body
   *******************************************************/
  $('.modal.fade', context).appendTo("body");


  /*******************************************************
   ***************************************** matchHeight
   *******************************************************/
  $('.configurations-card-modules-item-module-header-top', context).matchHeight({
    byRow: false,
    property: 'height'
  });
  $('.configurations-card-modules-item-module-header-price', context).matchHeight({
    byRow: false,
    property: 'height'
  });
  $('.configurations-card-modules-item-module-header-price-2', context).matchHeight({
    byRow: false,
    property: 'height'
  });
  $('.modules-add-item-module', context).matchHeight({
    byRow: true,
    property: 'height'
  });


  $('.job-offer-lead-body', context).matchHeight({
    byRow: true,
    property: 'height'
  });
  $('.our-products-item-text', context).matchHeight({
    byRow: true,
    property: 'height'
  });
  $('.timetable-item-module-body', context).matchHeight({
    byRow: false,
    property: 'height'
  });

  if($('.configurations-menu-nav').length > 0) {
    new slimScroll($('.configurations-menu-nav')[0]);
  }
}


$( document ).ready(function() {
  if(is_touch_device()) {
    $('body').addClass('touch-device');
  }


  /*******************************************************
   ***************************************** base simple helper inti
   *******************************************************/
   startBaseHelper();


  /*******************************************************
  ***************************************** fade scrolling
  *******************************************************/
   new FadeScrolling();


   /*******************************************************
   ***************************************** open tab by hash
   *******************************************************/
   if($('.tab-pane').length > 0 && window.location.hash) {
    var hash = window.location.hash;
    $('.nav a[href="' + hash + '"]').tab('show');
   }

});


$(window).on('load', function () {
  /*******************************************************
  ***************************************** scrollr
  *******************************************************/
  scrollrObj = skrollr.init();
  if (scrollrObj.isMobile()) {
    scrollrObj.destroy();
  }



  if (window.location.hash) {
    var hash = window.location.hash;
    if(hash.includes('-tab')) {
      var id = window.location.hash.replace('-tab', '');
      $('[href="' + id + '"]').click();
    }
  }

});
